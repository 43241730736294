import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  TFoot,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem,
  Pagination,
  Grid,
  ActionIcon,
  Modal,
  Card,
  Box,
  Select,
  TableTfoot,
  CloseButton,
  Loader,
} from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconEye} from '@tabler/icons-react';
import classes from './table-sort.scss';
import { useDisclosure } from '@mantine/hooks';
import { Amount, formatRut } from '../../../services/Utils';


const Th = ({ children, reversed, sorted, onSort }) => {
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <Table.Th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group justify="space-between">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
};



const TableSort = ({ paginatedData, headers, onPageChange, onPageSizeChange, isLoading, onSearch, onSort }) => {
  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(paginatedData.data);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [page, setPage] = useState(paginatedData.current_page);
  const [pageSize, setPageSize] = useState(paginatedData.per_page);
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedBill, setSelectedBill] = useState(null);

  useEffect(() => {
    setSortedData(paginatedData.data);
    setPage(paginatedData.current_page);
  }, [paginatedData]);

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    onSort(field, reversed);
  };

  const handleSearch = () => {
    //const { value } = event.currentTarget;
    //setSearch(value);
    onSearch(search);
    //setSortedData(sortData(paginatedData.data, { sortBy, reversed: reverseSortDirection, search: value }));
  };

  const handlePageChange = (page) => {
    setPage(page);
    onPageChange(page);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    onPageSizeChange(value);
  };

  const handleClearSearch = () => {
    setSearch('');
    onSearch('');
  };

  const getNestedValue = (obj, path) => {
    //obtiene un valor anidado de un objeto, utiliza un string con puntos para acceder a los valores
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const filterData = (data, search) => {
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
      headers.some((header) => {
        const value = getNestedValue(item, header.attribute)
        return value != null && value.toString().toLowerCase().includes(query)
      })
    );
  };
  /*
  const sortData = (data, { sortBy, reversed, search }) => {
    if (!sortBy) {
      return filterData(data, search);
    }
  
    return filterData(
      [...data].sort((a, b) => {
        const valueA = getNestedValue(a, sortBy) ? getNestedValue(a, sortBy) : ' ';
        const valueB = getNestedValue(b, sortBy) ? getNestedValue(b, sortBy) : ' ';
        if (reversed) {
          return valueB.toString().localeCompare(valueA.toString());
        }
        return valueA.toString().localeCompare(valueB.toString());
      }),
      search
    );
  };
  */
  const viewDetailModal = (bill,service) => {
    bill = { bill, service: {...service}}
    setSelectedBill(bill);
    open();
    console.log("Nuevo estado", opened);
  }

  const rows = sortedData.map((row, isLoading) => (
    <Table.Tr key={row.id}>
      {headers.map((header) => (
        header.parseMethod ? <Table.Td key={header.attribute}>{header.parseMethod(getNestedValue(row, header.attribute))}</Table.Td> :
        <Table.Td key={header.attribute}>{getNestedValue(row, header.attribute)}</Table.Td>
      ))}
      <Table.Td><ActionIcon onClick={ (event) => viewDetailModal(row.bill_takedata,row.service)} disabled={!row.bill_takedata} variant="outline"> <IconEye size={15}></IconEye></ActionIcon> </Table.Td>
    </Table.Tr>
    
  ));

  return (
    <>
    <Card shadow="sm" padding="lg" radius="md" withBorde>
      <>
          <Group justify="flex-end">        
                <TextInput
                  placeholder="Buscar según cualquier campo"
                  mb="md"
                  size='xs'
                  value={search}
                  onChange={(event) => setSearch(event.currentTarget.value)}
                  leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                  rightSection={
                    <CloseButton
                      aria-label="Clear input"
                      onClick={() => handleClearSearch()}
                      style={{ display: search ? undefined : 'none' }}
                    />
                  }
                />
                <Button mb="md" size='xs' color='#80bb00' onClick={handleSearch}>
                  Buscar   
                </Button>
          </Group>
          <ScrollArea>
            <Table highlightOnHover verticalSpacing="xs" miw={600} layout="fixed">
              <Table.Thead>
                <Table.Tr>
                  {headers.map((header) => (
                    <Th
                      className
                      key={header.attribute}
                      sorted={sortBy === header.attribute}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting(header.attribute)}
                    >
                      {header.label}
                    </Th>
                  ))}
                    <Th>
                      Opciones
                    </Th>
                </Table.Tr>
              </Table.Thead>
              { isLoading  ? 
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td colSpan={headers.length+1}>
                    <Center py={30}><Loader color='#80bb00'/></Center>
                  </Table.Td>
                </Table.Tr>
              </Table.Tbody> : 
              <Table.Tbody>
                {rows.length > 0 ? (
                  <>
                    {rows}
                  </>
                ) : (
                  <Table.Tr>
                    <Table.Td colSpan={headers.length+1}>
                      <Text fw={500} ta="center">
                        No se encontraron resultados
                      </Text>
                    </Table.Td>
                  </Table.Tr>
                )}
              </Table.Tbody>
              }       
            </Table>
            { (isLoading  || paginatedData.total == 0 )? "" :
            <Group m={0} justify="space-between">   
                  <Text size='xs'> {paginatedData.from} - {paginatedData.to} / {paginatedData.total} </Text>
                  <Pagination
                        total={paginatedData.last_page}
                        value={page}
                        onChange={handlePageChange}
                        m="xl"
                        color='#80bb00'
                        
                      />
                      <Group>
                        <Text size='xs'>Elementos por página</Text>
                        <Select
                          size="xs"
                          value={pageSize.toString()}
                          data={['10', '25', '50', '100']}
                          onChange={handlePageSizeChange}
                          maw={70}
                        />
                      </Group>
                    
            </Group>
            }
          </ScrollArea>
      </>
    </Card>
    <Modal size={'xl'} opened={opened} onClose={close} title="Detalle de Boleta" centered> 
        {selectedBill ? (
          <>
            <Box p="lg">
            <Text mb="sm" size='xl' fw={700}>Datos Boleta</Text>
            <Grid>
              <Grid.Col span={3}><Text fw={600}>ID b4ind:</Text></Grid.Col>
              <Grid.Col span={9}><Text>{selectedBill.bill.id}</Text></Grid.Col>

              <Grid.Col span={3}><Text fw={600}>Folio:</Text></Grid.Col>
              <Grid.Col span={9}><Text>{selectedBill.bill.folio}</Text></Grid.Col>

              <Grid.Col span={3}><Text fw={600}>Rut Receptor:</Text></Grid.Col>
              <Grid.Col span={9}><Text>{formatRut(selectedBill.bill.institution_rut)}</Text></Grid.Col>

              <Grid.Col span={3}><Text fw={600}>Fecha Emisión SII:</Text></Grid.Col>
              <Grid.Col span={9}><Text>{selectedBill.bill.issued_date}</Text></Grid.Col>

              <Grid.Col span={3}><Text fw={600}>Nombre Emisor:</Text></Grid.Col>
              <Grid.Col span={9}><Text>{selectedBill.bill.sender}</Text></Grid.Col>

              <Grid.Col span={3}><Text fw={600}>Rut Emisor:</Text></Grid.Col>
              <Grid.Col span={9}><Text>{formatRut(selectedBill.bill.rut)}</Text></Grid.Col>

              <Grid.Col span={3}><Text fw={600}>Glosa:</Text></Grid.Col>
              <Grid.Col span={9}><Text>{selectedBill.bill.description}</Text></Grid.Col>

              <Grid.Col span={3}><Text fw={600}>Monto:</Text></Grid.Col>
              <Grid.Col span={9}><Text>{Amount(selectedBill.bill.amount)}</Text></Grid.Col>

              <Grid.Col span={3}><Text fw={600}>Monto Retenido:</Text></Grid.Col>
              <Grid.Col span={9}><Text>{Amount(selectedBill.bill.tax_amount)}</Text></Grid.Col>

              <Grid.Col span={3}><Text fw={600}>Monto Líquido:</Text></Grid.Col>
              <Grid.Col span={9}><Text>{Amount(selectedBill.bill.amount - selectedBill.bill.tax_amount)}</Text></Grid.Col>
            </Grid>

            <Text mt="xl" my="sm" size='xl' fw={700}>Datos del servicio asociado</Text>
            <Grid>
              <Grid.Col span={3}><Text fw={600}>ID b4ind:</Text></Grid.Col>
              <Grid.Col span={9}><Text>{selectedBill.service.id}</Text></Grid.Col>

              <Grid.Col span={3}><Text fw={600}>Nombre:</Text></Grid.Col>
              <Grid.Col span={9}><Text>{selectedBill.service.name}</Text></Grid.Col>
            </Grid>
          </Box>
          </>
        ) : (
          'Aún no existe boleta relacionada a la solicitud.'
        )}
    </Modal>
  </>
  );
};

export default TableSort;
